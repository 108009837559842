'use client';

import * as Sentry from '@sentry/nextjs';
import { FC } from 'react';

import type { getCurrentUser } from '@sentinel/extras-session/userSession';

type Props = {
  user: Awaited<ReturnType<typeof getCurrentUser>>;
};

export const SentryClientUser: FC<Props> = ({ user }) => {
  Sentry.setUser(user);

  return null;
};

'use client';
import Link from 'next/link';
import { type FC, useState } from 'react';

import { CollapsibleMenu } from './collapsibleMenu';
import Logo from '../../assets/images/logo.svg';

export const Main: FC = () => {
  const [nav, setNav] = useState(false);

  return (
    <nav id="main-nav" className="sticky">
      <div className="container flex flex-wrap justify-center mx-auto">
        <Link href="https://modx.com" className="navbar-brand">
          <img src={Logo.src} width={Logo.width} height={Logo.height} alt="MODX" />
        </Link>
        <button
          className="hamburger hamburger--collapse block lg:hidden"
          type="button"
          onClick={(): void => {
            setNav((prev) => !prev);
          }}
          aria-expanded={nav}>
          <div className="hamburger-box">
            <div className="hamburger-inner" />
          </div>
          <span className="sr-only">Menu</span>
        </button>
        <div className={`navbar-dropdown lg:block ${nav ? 'block' : 'hidden'}`}>
          <ul className="navbar-nav">
            <CollapsibleMenu title="Products">
              <ul>
                <li>
                  <Link href="https://modx.com/content-management-framework">Open Source CMS</Link>
                </li>
                <li>
                  <Link href="https://modx.com/content-management-framework">
                    Managed Cloud Hosting
                  </Link>
                </li>
                <li>
                  <Link href="https://modx.com/content-management-framework">
                    Backstage&trade; Selective Publishing
                  </Link>
                </li>
              </ul>
            </CollapsibleMenu>
            <li>
              <Link href="https://modx.com/pricing">Pricing</Link>
            </li>
            <li>
              <Link href="https://modx.com/blog">Blog</Link>
            </li>
            <CollapsibleMenu title="Resources">
              <ul>
                <li>
                  <Link href="https://modx.com/resources/">Resource Hub</Link>
                </li>
                <li>
                  <Link href="https://modx.com/professionals/">Professionals</Link>
                </li>
                <li>
                  <Link href="https://community.modx.com/">Community Forums</Link>
                </li>
                <li>
                  <Link href="https://docs.modx.com/">Documentation</Link>
                </li>
                <li>
                  <Link href="https://modx.com/resources/customers">Customers</Link>
                </li>
              </ul>
            </CollapsibleMenu>
            <li className="lg:ml-4 lg:mr-1">
              <Link href="https://modx.com/cloud-demo" className="btn btn-primary">
                Request a Demo
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

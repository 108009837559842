'use client';

import Script from 'next/script';
import React, { FC } from 'react';

import { initAnalytics } from '../utils/analytics';

export const GaInit: FC = () => {
  return (
    <Script
      src="https://www.googletagmanager.com/gtag/js?id=G-E71SH14LRR"
      strategy="afterInteractive"
      onLoad={(): void => {
        initAnalytics();
      }}
    />
  );
};

'use client';

import { AppProgressBar } from 'next-nprogress-bar';

import type { ReactNode } from 'react';

const Progress = ({ children }: { children: ReactNode }) => {
  return (
    <>
      {children}
      <AppProgressBar color="#29d" options={{ showSpinner: false }} shallowRouting />
    </>
  );
};

export default Progress;

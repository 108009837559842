import { Dispatch, SetStateAction, useState } from 'react';

export const useToggle = (
  defaultValue = false,
): [boolean, () => void, Dispatch<SetStateAction<boolean>>] => {
  const [value, setValue] = useState(defaultValue);
  const toggle = (): void => setValue(!value);

  return [value, toggle, setValue];
};

'use client';

import { FC, PropsWithChildren, ReactNode, useEffect, useRef } from 'react';

import { useToggle } from '../../hooks/useToggle';

type Props = {
  title: string | ReactNode;
};

export const CollapsibleMenu: FC<PropsWithChildren<Props>> = ({ title, children }) => {
  const itemRef = useRef<HTMLLIElement | null>(null);
  const [expanded, toggleExpanded, setExpanded] = useToggle(false);

  useEffect(() => {
    const handleClickOutside = (ev: MouseEvent | FocusEvent): void => {
      if (itemRef?.current && !itemRef?.current?.contains(ev.target as Node)) {
        setExpanded(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('focusin', handleClickOutside);
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.addEventListener('focusin', handleClickOutside);
    };
  }, [setExpanded]);

  return (
    <li className="dropdown" ref={itemRef}>
      <button
        onClick={(): void => {
          toggleExpanded();
        }}
        aria-expanded={expanded}>
        {title}
      </button>
      {expanded && children}
    </li>
  );
};

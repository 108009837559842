declare global {
  interface Window {
    dataLayer?: unknown[];
  }
}

export const initAnalytics = (): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ js: new Date() });
  window.dataLayer.push({ config: 'G-E71SH14LRR' });
};

export const logUrl = (url: string): void => {
  if (!window.dataLayer) return;
  //if (url.includes('?')) return;

  window.dataLayer.push({
    event: 'pageview',
    pageData: { path: url, title: document.title },
  });
};
